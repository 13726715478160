import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { Specification } from 'modules/projects/components/specification';
import { trackCustomEventGoogleAnalytics } from 'utilities/index';

const SContainer = styled.div`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

export const TechnologiesMainSection = () => {
    const googleAnalytics = (value) => {
        trackCustomEventGoogleAnalytics(value);
    };

    return (
        <SContainer>
            <Specification
                texts={{
                    topSubTitle: 'technologies-page.technologies',
                    title: 'technologies-page.technologiesThatWillRedefine',
                    subtitle: 'technologies-page.firstClassSolutions',
                    buttonFirst: 'general.estimateProject',
                    buttonSecond: 'general.introCall',
                }}
                firstBtnOptions={{
                    navigateTo: PATHS.ESTIMATE_THE_PROJECT,
                    handleClick: () =>
                        googleAnalytics(
                            gaTrackedEvents.SERVICES.CTA.ESTIMATE_PROJECT,
                        ),
                }}
                secondBtnOptions={{
                    component: 'a',
                    navigateTo: EXTERNAL_REDIRECTS.HUB_SPOT_MEETING,
                    handleClick: () =>
                        googleAnalytics(
                            gaTrackedEvents.SERVICES.CTA.INTRO_CALL,
                        ),
                }}
                isBoxStyle
            />
        </SContainer>
    );
};
