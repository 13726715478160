import React from 'react';
import styled from 'styled-components';
import { slidingAnimation, spinningAnimation } from 'styles/utilities';
import TechnicalAnalysisImage from 'svgs/images/technical-analysis.svg';

const SAnimatedTechnicalAnalysis = styled.div`
    width: 100%;
    height: 100%;
    #cog {
        ${spinningAnimation};
    }
    #tags {
        ${slidingAnimation(-10, 0)};
    }
    #magnifier {
        ${slidingAnimation(0, -10)};
    }
    #shape-1 {
        ${slidingAnimation(-5, 5)};
    }
    #shape-2 {
        ${slidingAnimation(13, -3)};
    }
    #shape-3 {
        ${slidingAnimation(5, -5)};
    }
    #shape-4 {
        ${slidingAnimation(3, -3)};
    }
    #shape-5 {
        ${slidingAnimation(5, 5)};
    }
`;

export const AnimatedTechnicalAnalysis = () => {
    return (
        <SAnimatedTechnicalAnalysis>
            <TechnicalAnalysisImage width="100%" height="100%" />
        </SAnimatedTechnicalAnalysis>
    );
};
