import React from 'react';
import styled from 'styled-components';
import { AnimatedTechnologies } from 'components/animated-technologies';
import { Container } from 'components/container';
import { RowWithTextImg } from 'modules/row-text-img';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

export const ExpertsSection = () => {
    return (
        <SContainer>
            <RowWithTextImg
                title="technologies-page.theTeamOfSoftware"
                descriptions={[
                    'technologies-page.weOfferCustom',
                    'technologies-page.weUseBestPractice',
                ]}
                img={<AnimatedTechnologies />}
            />
        </SContainer>
    );
};
