import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { bool, object, string } from 'prop-types';

const SContainer = styled(Container)`
    padding-top: 3rem;
    margin-top: -3rem;
    content-visibility: auto;
    contain-intrinsic-size: 500px;
`;

const SWrapper = styled.div`
    background-color: var(--gray-color-90);
    display: flex;
    gap: 1rem;
    padding: 0 var(--section-clamp);

    ${CONSTANTS.MEDIA.min1024`
        padding-right: clamp(1rem, -0.2324rem + 5.2582vw, 4.5rem);
    `}

    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
    `};
`;

const SParagraph = styled(Paragraph)`
    margin-top: 1.5rem;
`;

const SButtons = styled.div`
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.563rem;
`;

const SLeft = styled.div`
    flex: 1;
    padding: 4.625rem 0;
    align-self: center;

    ${CONSTANTS.MEDIA.max768`
        padding: 4rem 0 2rem;
    `};
`;

const SRight = styled.div`
    display: flex;
    flex: 0.7;
    align-items: flex-end;

    ${CONSTANTS.MEDIA.max900`
        flex: 1;
    `}

    ${CONSTANTS.MEDIA.max768`
        margin-top: 0;
        flex-direction: column;
        align-items: center;
    `}
`;

const SImage = styled(GatsbyImage)`
    width: 23.25rem;
    transform: scale(1.1);
    top: -5%;

    ${CONSTANTS.MEDIA.max1024`
        transform: scale(1);
        top: 0;
    `}

    ${CONSTANTS.MEDIA.max500`
        width: 100%;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    .block {
        display: block;
    }
`;

const SFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-right: 1.5rem;

    ${CONSTANTS.MEDIA.max1024`
        margin-top: 1rem;
    `}

    ${CONSTANTS.MEDIA.max768`
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        margin-top: 2rem;
    `}
`;

const SFooterTitle = styled(Paragraph)`
    font-size: 1rem;
    font-weight: 700;
`;

const SFooterSubTitle = styled(Paragraph)`
    font-size: 1rem;
`;

export const RequestSection = ({
    className,
    title,
    showDescription,
    isContact,
    imagesData,
    alt,
    description,
}) => {
    const images = useStaticQuery(graphql`
        {
            ceo: file(relativePath: { eq: "team/matt-sadowski-ceo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 436
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    const imageObj = imagesData?.ceo?.childImageSharp
        ? { image: imagesData.ceo.childImageSharp.gatsbyImageData, alt }
        : {
              image: images.ceo.childImageSharp.gatsbyImageData,
              alt: alt || 'CEO of Mobile Reality',
          };

    const titleId = title || 'request-section.breakTheRules';

    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(titleId);

    return (
        <SContainer className={className} id="request">
            <SWrapper>
                <SLeft>
                    <SHeaderSecond
                        id={kebabCaseId}
                        handleCopyUrl={handleCopyUrl}
                    >
                        <FormattedMessage id={titleId} />
                    </SHeaderSecond>
                    {showDescription && (
                        <SParagraph>
                            <FormattedMessage
                                id={
                                    description ||
                                    'request-section.requestACall'
                                }
                            />
                        </SParagraph>
                    )}
                    <SButtons>
                        {isContact ? (
                            <Button
                                to={PATHS.CONTACT_US}
                                component="gatsby-link"
                            >
                                <FormattedMessage id="content.navigation.ContactUs" />
                            </Button>
                        ) : (
                            <Button
                                to={PATHS.ESTIMATE_THE_PROJECT}
                                component="gatsby-link"
                            >
                                <FormattedMessage id="general.estimateProject" />
                            </Button>
                        )}
                        <Button
                            variant="outline"
                            to={EXTERNAL_REDIRECTS.HUB_SPOT_MEETING}
                            component="a"
                        >
                            <FormattedMessage id="general.introCall" />
                        </Button>
                    </SButtons>
                    <SFooterContainer>
                        <SFooterTitle>
                            <FormattedMessage id="request-section.ceoName" />
                        </SFooterTitle>
                        <SFooterSubTitle>
                            <FormattedMessage id="request-section.ceoOfMobileReality" />
                        </SFooterSubTitle>
                    </SFooterContainer>
                </SLeft>
                <SRight>
                    <SImage alt={imageObj.alt} image={imageObj.image} />
                </SRight>
            </SWrapper>
        </SContainer>
    );
};

RequestSection.propTypes = {
    className: string,
    title: string,
    showDescription: bool,
    isContact: bool,
    imagesData: object,
    alt: string,
    description: string,
};

RequestSection.defaultProps = {
    className: '',
    title: '',
    showDescription: true,
    isContact: false,
    imagesData: {},
    alt: '',
    description: '',
};
