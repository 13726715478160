import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { AnimatedTechnicalAnalysis } from 'components/animated-technical-analysis';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 500px;
`;

const SWrapper = styled.div`
    padding: 3.25rem var(--section-clamp);
    display: flex;
    gap: 4.375rem;
    align-items: center;
    border: 2px solid var(--primary-color);
    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
        gap: 2.375rem
    `}
`;

const SImageWrapper = styled.div`
    flex: 0.44;
    max-width: 27.75rem;
`;

const SText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0.56;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 3rem;
`;

const SButton = styled(Button)`
    width: fit-content;
`;

export const AuditSection = () => {
    return (
        <SContainer>
            <SWrapper>
                <SImageWrapper>
                    <AnimatedTechnicalAnalysis />
                </SImageWrapper>
                <SText>
                    <SHeaderSecond>
                        <FormattedMessage id="technologies-page.exploreOurTechnological" />
                    </SHeaderSecond>
                    <SParagraph>
                        <FormattedMessage id="technologies-page.ourStrategy" />
                    </SParagraph>
                    <SButton
                        to={`${PATHS.EXPERTISES}/${PATHS.TECHNICAL_DUE_DILIGENCE}`}
                        component="gatsby-link"
                    >
                        <FormattedMessage id="technologies-page.findOutHow" />
                    </SButton>
                </SText>
            </SWrapper>
        </SContainer>
    );
};
